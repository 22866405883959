import React, { useState, useEffect } from "react"
import resolveLink from "~utils/resolveLink"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import sanityClient from '@sanity/client'
import { typeColors } from "~styles/global"
import { css } from "@emotion/react"

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_API_TOKEN,
  useCdn: false
})

const SanityLink = ({ id, children }) => {
  const [to, setTo] = useState('/#')
  const [type, setType] = useState(undefined)
  useEffect(() => {
    if(id){
      client.fetch(`*[_id == '${id}']`).then(docs => {
        if(docs.length){
          setTo(resolveLink(docs[0]))
          setType(docs[0]._type)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Link to={to} className="internal-link" css={css`
      transition: text-shadow 0.4s;
      text-shadow: 0px 4px 4px ${type ? typeColors[type] : '#999999'};
      `}>
      {children}
    </Link>
  )
}

const serializers = {
  marks: {
    internalLink: props => {
      return <SanityLink id={props.mark.page?._id}>{props.children}</SanityLink>
    }
  }
}

const RichText = ({ content }) => <BlockContent blocks={content} serializers={serializers}/>

export default RichText
